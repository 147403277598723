import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Наші послуги | Послуги курорту Fitzensa
			</title>
			<meta name={"description"} content={"Побалуйте себе елегантністю - насолодіться розкішшю в готелі Fitzensa"} />
			<meta property={"og:title"} content={"Наші послуги | Послуги курорту Fitzensa"} />
			<meta property={"og:description"} content={"Побалуйте себе елегантністю - насолодіться розкішшю в готелі Fitzensa"} />
			<meta property={"og:image"} content={"https://fitzensa.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fitzensa.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fitzensa.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fitzensa.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fitzensa.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fitzensa.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fitzensa.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong sm-text-align="left">
					Наші послуги
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Основні послуги
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Fitzensa Resort - це симфонія послуг, які задовольняють ваше прагнення до релаксації та елегантності. Від спа-центру світового класу до кулінарних шедеврів нашого ресторану - кожен аспект створений для того, щоб забезпечити вам відчуття безтурботності.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
					Проживання з комфортом
					</Text>
					<Image src="https://fitzensa.com/img/6.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					- Розкішні люкси: Розслабтеся в наших просторих люксах з елегантним декором, плюшевими постільними речами та безтурботними краєвидами, що забезпечить вам спокійний відпочинок.
					<br /><br />
- Обслуговування номерів: Скористайтеся нашим цілодобовим обслуговуванням номерів з меню, яке доставить наші кулінарні шедеври прямо до ваших дверей.

						
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
					Спа та оздоровлення
					</Text>
					<Image src="https://fitzensa.com/img/7.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					- Спа-центр із повним спектром послуг: Вибирайте з меню спа-процедур, включаючи масажі, процедури з догляду за обличчям та обгортання для омолодження.
					<br /><br />
- Фітнес-центр: Підтримуйте свій оздоровчий режим за допомогою найсучаснішого обладнання та персональних тренувань у нашому повністю обладнаному тренажерному залі.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
					Гастрономія
					</Text>
					<Image src="https://fitzensa.com/img/8.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					- Харчування для гурманів: Наш ресторан на території готелю пропонує вишукані страви, приготовані з найсвіжіших місцевих продуктів.
					<br /><br />
- Бар біля басейну: Скуштуйте фірмові коктейлі або насолодіться легкою їжею біля басейну в оточенні спокійних звуків природи.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="20px 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
					Дозвілля та розваги
					</Text>
					<Image src="https://fitzensa.com/img/9.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					- Басейн: Неспішно поплавайте або розслабтеся біля нашого басейну - спокійного місця, де можна насолодитися сонцем і мирною атмосферою.
					<br /><br />
- Прогулянки в саду: Прогулянка нашими прекрасними ландшафтними садами - ідеальний спосіб відновити зв'язок з природою.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
					Проведення заходів
					</Text>
					<Image src="https://fitzensa.com/img/10.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					- Елегантні зали: Наш курорт обладнаний для проведення ваших заходів, від інтимних зустрічей до великих святкувань, і все це в нашій спокійній обстановці.
					<br/><br/>
- Професійне планування: Дозвольте нашим фахівцям з організації заходів допомогти вам у створенні бездоганної події, яка відображатиме ваше бачення.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
					Дослідження та релаксація
					</Text>
					<Image src="https://fitzensa.com/img/11.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					- Бібліотека та лаунж: Пориньте у сторінки книги або насолодіться тишею в нашій добре обладнаній бібліотеці та лаунж-зоні.
					<br/><br/>
- Активний консьєрж: Наш консьєрж-сервіс може організувати місцеві екскурсії або запропонувати заходи, щоб урізноманітнити ваше перебування.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://fitzensa.com/img/12.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Зверніться до Fitzensa
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Щоб ознайомитися з повним спектром наших послуг або забронювати безтурботний відпочинок, будь ласка, зв'яжіться з нашим привітним персоналом. Ми прагнемо, щоб ваше перебування стало втіленням релаксації та індивідуальної розкоші.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});